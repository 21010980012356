import React, { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { Dialog, Transition } from '@headlessui/react';
import aa from 'search-insights';

import { Cart, EstrellaGalicia, Search, User, UserActive } from '@/icons';
import { CustomSkeletonTheme, NextLink } from '@/atoms';
import { Button } from '@/molecules';
import { ToastCenter } from '@/organisms';
import CartLines from '@/organisms/cart-lines';

import { useCart, useEmployee, useRouteChangeStart } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  gtmPush,
  isFreeShipping,
  normalizeCartItems,
  priceToNumber,
  SumCurrency,
  toCurrency,
} from '@/lib/utils';

import type { ToolbarProps } from './types';

const SearchBoxMobileInstantSearch = dynamic(
  () => import('@/components/algolia/search-box-mobile-instant-search'),
  { ssr: false },
);

const LoginModal = dynamic(() => import('@/organisms/modals/LoginModal'), {
  ssr: false,
});

export const Toolbar = ({
  showSearchIcon = true,
  className,
  children,
}: ToolbarProps) => {
  const { status, data }: any = useSession();

  // Optional: set the analytics user ID
  aa('setUserToken', data?.user?.UID);

  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showToolbar, setShowToolbar] = useState(false);
  const { isEmployee } = useEmployee();

  const close = () => setSearching(false);

  const handleRouteChange = useCallback(() => {
    setShowToolbar(false);
    close();
  }, []);

  useRouteChangeStart(handleRouteChange);

  const { cart, loading } = useCart();

  return (
    <>
      <div
        className={`flex h-full items-center justify-end max-lg:gap-x-6 ${className ?? ''}`}
      >
        {showSearchIcon && (
          <div className="flex lg:hidden">
            <div onClick={() => setSearching(true)} role="button">
              <span className="sr-only">Search</span>
              <Search className="h-5 w-5" aria-hidden="true" />
            </div>
          </div>
        )}

        {/* User */}
        {status === 'authenticated' && (
          <>
            <NextLink href={routes.micuenta.index} passHref>
              <a className="u-body u-body--s flex flex-row gap-1 font-bold text-primary-900">
                <span className="max-lg:hidden" data-playwright="txt_userName">
                  {data?.user?.name}
                </span>
                {isEmployee ? (
                  <EstrellaGalicia width={20} height={20} aria-hidden="true" />
                ) : (
                  <UserActive width={20} height={20} aria-hidden="true" />
                )}
              </a>
            </NextLink>
          </>
        )}

        {status !== 'authenticated' && (
          <div
            role="button"
            className="flex gap-1 lg:items-center"
            onClick={() => {
              setLoginModalOpen(true);
            }}
            data-playwright="openLoginModal"
            aria-label="Inicia Sesión"
          >
            <p className="u-body u-body--s font-bold text-primary-900 max-lg:hidden">
              Inicia sesión
            </p>
            <User width={20} height={20} aria-hidden="true" />
          </div>
        )}

        <LoginModal
          show={loginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          onSuccess={() => setLoginModalOpen(false)}
        />

        {/* Cart Desktop */}
        <div
          className="relative ml-6 flex items-center max-lg:hidden"
          {...(cart.contents.itemCount > 0
            ? {
                onMouseEnter: () => setShowToolbar(true),
                onMouseLeave: () => setShowToolbar(false),
              }
            : {})}
        >
          <div className="relative flex items-center pr-4">
            <Cart className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            {children}
            {showToolbar && cart.contents.itemCount > 0 && (
              <div className="absolute -right-5 top-0 z-[70] w-[370px] flex-col items-center justify-center pt-10">
                <div className="absolute right-[35px] top-[30px] z-0 h-5 w-5 rotate-45 border border-primary-100 bg-white shadow-lg" />
                <div className="relative z-10 h-full w-full border border-primary-100 bg-white shadow-lg">
                  <div className="absolute -top-px right-[31px] h-px w-[26px] bg-white" />

                  <div className="flex flex-col divide-y-[1px] divide-primary-100">
                    <div className="flex flex-col gap-y-2 p-4">
                      <NextLink href={routes.cart} passHref>
                        <Button size="normal-full" as="a" testid="go-to-cart">
                          IR A CESTA
                        </Button>
                      </NextLink>

                      <NextLink href={routes.checkout.datos} passHref>
                        <Button
                          size="normal-full"
                          variant="secondary"
                          as="a"
                          testid="process-order"
                          onClick={() => {
                            gtmPush({
                              event: 'begin_checkout',
                              ecommerce: {
                                discount: priceToNumber(
                                  SumCurrency(
                                    cart.discountTotal,
                                    cart.discountTax,
                                  ),
                                ),
                                coupon: cart.appliedCoupons,
                                items: normalizeCartItems(cart),
                              },
                            });
                          }}
                        >
                          Tramitar pedido
                        </Button>
                      </NextLink>
                    </div>

                    <div className="px-2">
                      <div className="max-h-96 overflow-y-auto p-2 scrollbar scrollbar-track-primary-100 scrollbar-thumb-primary-900 scrollbar-w-1">
                        <CartLines
                          cart={cart.contents.cart}
                          loading={loading}
                          className="mx-px"
                        />
                      </div>
                    </div>

                    <CustomSkeletonTheme>
                      <div className="px-4 py-2">
                        <div className="flex flex-col gap-y-2 border-b border-primary-100 pb-2">
                          <div className="flex flex-row items-baseline justify-between self-stretch px-1">
                            <p className="u-body u-body--s flex flex-row items-baseline gap-x-2 text-primary-900">
                              Subtotal
                              <span className="u-body--xs text-primary-600">
                                (IVA incl.)
                              </span>
                            </p>
                            {loading ? (
                              <Skeleton count={1} width={80} />
                            ) : (
                              <p className="u-body u-body--s text-primary-600">
                                {toCurrency(
                                  SumCurrency(cart.subtotal, cart.subtotalTax),
                                )}
                              </p>
                            )}
                          </div>
                          <div className="flex flex-row items-baseline justify-between self-stretch px-1">
                            <p className="u-body u-body--s flex flex-row items-baseline gap-x-2 text-primary-900">
                              Envío
                              <span className="u-body--xs text-primary-600">
                                (IVA incl.)
                              </span>
                            </p>
                            {loading ? (
                              <Skeleton count={1} width={80} />
                            ) : isFreeShipping(
                                SumCurrency(
                                  cart.shippingTotal,
                                  cart.shippingTax,
                                ),
                              ) ? (
                              <p className="u-body u-body--s text-status-success">
                                GRATIS
                              </p>
                            ) : (
                              <p className="u-body u-body--s text-primary-600">
                                {SumCurrency(
                                  cart.shippingTotal,
                                  cart.shippingTax,
                                )}
                              </p>
                            )}
                          </div>
                          {loading ? (
                            <Skeleton count={1} containerClassName="px-1" />
                          ) : (
                            cart.appliedCoupons &&
                            cart.appliedCoupons.length > 0 && (
                              <div className="flex flex-row items-baseline justify-between self-stretch px-1">
                                <p className="u-body u-body--s flex flex-row items-baseline gap-x-2 text-primary-900">
                                  Descuentos aplicados
                                </p>
                                <p className="u-body u-body--s text-secondary-500">
                                  -
                                  {cart.appliedCoupons.reduce(
                                    (prev, curr) =>
                                      SumCurrency(
                                        prev,
                                        SumCurrency(
                                          curr.discountAmount,
                                          curr.discountTax,
                                        ),
                                      ),
                                    '0€',
                                  )}
                                </p>
                              </div>
                            )
                          )}
                        </div>

                        <div className="flex w-full items-center justify-between pb-5 pt-3">
                          <p className="u-headline u-headline@mobile--h1 flex flex-row items-baseline gap-x-2">
                            Total
                            <span className="u-body u-body--s text-primary-600">
                              (IVA incl.)
                            </span>
                          </p>
                          <p className="u-headline u-headline@mobile--h1">
                            {loading ? (
                              <Skeleton count={1} width={80} />
                            ) : (
                              toCurrency(cart.total)
                            )}
                          </p>
                        </div>
                      </div>
                    </CustomSkeletonTheme>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Cart Mobile */}
        <NextLink href={routes.cart}>
          <a
            className={`relative flex items-center lg:hidden ${cart.contents.itemCount > 0 ? 'pr-4' : ''}`}
          >
            <Cart className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            {children}
          </a>
        </NextLink>
      </div>

      {/* SearchBox Mobile */}
      <Transition
        show={searching}
        as="div"
        className="hidden"
        unmount={false}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <Dialog
          unmount={false}
          as="div"
          className="fixed inset-0 z-40 flex"
          onClose={close}
          open={searching}
        >
          <div className="flex h-full w-full flex-col items-end bg-bg-secondary">
            <ToastCenter
              style={{
                position: 'absolute',
                zIndex: 40,
                width: '100%',
                inset: 'auto',
              }}
            />

            {searching ? (
              <SearchBoxMobileInstantSearch
                placeholder="¿Qué estás buscando?"
                onClose={close}
              />
            ) : null}
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
